import React from "react"

import { NavMenuItemWrapper } from "../styles/MobileNavigation.styled"

const MobileMenuItem = ({ title, icon, url, key }) => (
  <NavMenuItemWrapper className="nav-menu-item--wrapper" key={key}>
    <a className="nav-menu-item--link" href={url || "#"} aria-label={title}>
      <div className="nav-menu-item--icon">
        <img src={icon?.file?.url} alt={icon?.title || "title"} />
      </div>
      <div className="nav-menu-item--content">
        <span>{title}</span>
      </div>
    </a>
  </NavMenuItemWrapper>
)

export default MobileMenuItem
