import React from 'react';
import styled from 'styled-components';
import { theme } from '@theme';

export const Heading = ({ headingSize, background, textVariant, children, className, ...props }) => {

  const headingColorObj = {
    'light': theme.textColor.heading,
    'dark': theme.textColor.headingLight,
    'alternate': theme.textColor.heading,
    'white': theme.textColor.grey800,
  }

  const headingStyles = {
    color: `${ background ? headingColorObj[background] : theme.textColor.heading }`,
    fontFamily: `${ theme.font.primary }`,
    ...props.style
  };

  switch (headingSize) {
    case "H1":
      return <H1 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H1>;
    case "H2":
      return <H2 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H2>;
    case "H3":
      return <H3 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H3>;
    case "H4":
      return <H4 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H4>;
    case "H5":
      return <H5 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H5>;
    case "H6":
      return <H6 theme={ theme } headingSize={ headingSize } className={ className } style={{ ...headingStyles }}>{children && children}</H6>;
  }
};

const H1 = styled.h1`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
    @media (max-width: 576px) {
      font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
      line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]};
    }
  `}
`


const H2 = styled.h2`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
  @media (max-width: 576px) {
    font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
    line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]} !important;
  }
  `}
`
const H3 = styled.h3`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
  @media (max-width: 576px) {
    font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
    line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]};
  }
  `}
`
const H4 = styled.h4`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
  @media (max-width: 576px) {
    font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
    line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]};
  }
  `}
`
const H5 = styled.h5`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
  @media (max-width: 576px) {
    font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
    line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]};
  }
  `}
`
const H6 = styled.h6`
  ${({ headingSize, theme }) =>
  `
  font-size: ${ theme.typeScale[headingSize[1]]};
  line-height: ${ theme.lineHeights[headingSize[1]]};
  @media (max-width: 576px) {
    font-size: ${ theme.typeScale[String(parseInt(headingSize[1], 10) + 1)]};
    line-height: ${ theme.lineHeights[String(parseInt(headingSize[1], 10) + 1)]};
  }
  `}
`

