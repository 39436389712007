import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { IconButton } from "@atoms"
import { useIsScrolled } from "@hooks/useIsScrolled"
import {
  DesktopNavWrapper,
  NavItemWrapper,
} from "../styles/DesktopNavigation.styled"
import SearchBar from "../components/Searchbar"

import WGLogo from "@assets/icons/logo.svg"
import WGLogoWhite from "@assets/icons/logo-white.svg"

import NavItem from "../components/NavItem"
import onKeyDown from "../../../utils/onKeyDown"

const DesktopNavigation = ({ navigationData, theme = { theme: "light" } }) => {
  const [filteredTheme, setFilteredTheme] = useState({ ...theme })
  const [searchActive, setSearchActive] = useState(false)
  const { isScrolled } = useIsScrolled()
  const location = typeof window !== `undefined` && window.location.pathname

  useEffect(() => {
    if (isScrolled) {
      setFilteredTheme({ theme: "light" })
    } else {
      setFilteredTheme({ ...theme })
    }
  }, [isScrolled])

  return (
    <DesktopNavWrapper
      data-comp={DesktopNavigation.displayName}
      theme={filteredTheme}
    >
      <div className="nav--container">
        <div className="nav--left">
          <Link to="/" aria-label="Homepage">
            <img
              alt="workgrid logo"
              className="nav--logo"
              src={filteredTheme?.theme === "dark" ? WGLogoWhite : WGLogo}
            />
          </Link>
        </div>
        <div className="nav--center">
          {navigationData?.navigationItems?.map((navItem, index) => (
            <NavItem
              navContent={navItem}
              sidebarLinks={navigationData?.sidebarLinks}
              sidebarFeatured={navigationData?.sidebarFeatured}
              index={index}
              theme={filteredTheme}
            />
          ))}
          {location !== "/global-search" && (
            <NavItemWrapper
              className="nav--item"
              onClick={() => setSearchActive(!searchActive)}
              onKeyDown={e =>
                onKeyDown(e, () => setSearchActive(!searchActive))
              }
              aria-label={
                searchActive ? `Close global search` : `Open global search`
              }
              aria-expanded={searchActive}
              tabIndex={0}
            >
              <span className="nav--item-link">
                <img
                  alt="search icon"
                  className="nav--item-link-icon"
                  src={
                    filteredTheme?.theme === "dark"
                      ? "/icons/nav-search-white.svg"
                      : "/icons/nav-search.svg"
                  }
                />
              </span>
            </NavItemWrapper>
          )}
        </div>
        <div className="cta-wrapper">
          {navigationData?.secondaryCta && (
            <div className="nav--right">
              <IconButton
                ctaVariant={
                  filteredTheme?.theme === "dark" ? "tertiary" : "secondary"
                }
                ctaLink={navigationData.secondaryCta?.link}
              >
                {navigationData.secondaryCta?.label}
              </IconButton>
            </div>
          )}
          <div className="nav--right">
            <IconButton
              ctaVariant={
                filteredTheme?.theme === "dark" ? "secondary" : "primary"
              }
              ctaLink={navigationData?.navigationCta?.link}
            >
              {navigationData?.navigationCta?.label}
            </IconButton>
          </div>
        </div>
      </div>
      <SearchBar
        searchActive={searchActive}
        className={
          searchActive && location !== "/global-search"
            ? "seachbar-active"
            : "searchbar"
        }
      />
    </DesktopNavWrapper>
  )
}

export default DesktopNavigation
DesktopNavigation.displayName = "DesktopNavigation"
