import styled from "styled-components"

export const MobileNavWrapper = styled.div`
  background: ${props =>
    props.menuOpened === false ? props.theme?.background || "#FFF" : "#FFF"};
  border-bottom: ${props =>
    props.menuOpened === false
      ? props.theme?.background || "1px solid #E4E9F1"
      : "1px solid #E4E9F1"};
  transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  .nav--container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 992px) {
      max-width: 770px;
    }
    @media (max-width: 768px) {
      max-width: 506px;
    }
  }
  .nav--left {
    .nav--logo {
      width: 170px;
      height: auto;
    }
    cursor: pointer;
    @media (hover) {
    }
  }
  .nav--right {
    display: flex;
    align-items: center;
    justify-content: center;
    .mobile-nav-item--search {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .hamburger-react {
      &:focus-visible {
        border: 1px solid orange;
        border-radius: 10px;
      }
    }
    button {
      padding: 9px 29px !important;
      height: auto !important;
      font-weight: bold;
      line-height: 27px !important;
      width: 204px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 9px;
    }
    @media (max-width: 768px) {
      .mobile-nav-item--search,
      button {
        display: none;
      }
    }
  }
`

export const NavMenuContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
  top: 100px;
  background: ${props => props.theme?.background || "#FFF"};
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  overflow: auto;
  &.nav--menu-opened {
    opacity: 1;
    visibility: visible;
  }
  .nav--menu-container-wrapper {
  }
  .nav--menu-search-container {
    background: #f2f4f7;
    padding: 20px 60px;
    @media (max-width: 992px) {
      padding: 20px 40px;
    }
    @media (max-width: 576px) {
      padding: 20px 35px;
    }
    .nav--menu-search-container-wrapper {
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e4e7ec;
      box-sizing: border-box;
      border-radius: 35px;
      padding: 8px 30px;
      position: relative;
      input {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        border: none;
        outline: none;
        &::placeholder {
          color: #98a2b3;
        }
      }
      #search-icon {
        position: absolute;
        right: 10px;
        top: 0;
        img {
          margin-top: 11px;
          margin-left: 10px;
        }
      }
    }
  }
  .nav--menu-footer-container {
    height: fit-content;
    .nav--menu-footer-container-wrapper {
      .background {
        position: absolute;
        z-index: 0;
        min-width: 100%;
        height: 100%;
      }
      padding: 56px 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        color: #ffffff;
        text-align: center;
        margin: 0 0 30px;
        z-index: 1;
      }
      a {
        text-decoration: none;
        z-index: 1;
      }
      button {
        padding: 9px 29px !important;
        height: auto !important;
        font-weight: bold;
        line-height: 27px !important;
      }
      .cta-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        width: 100%;

        a,
        button {
          width: 100%;
          @media (min-width: 768px) {
            width: fit-content;
          }
        }

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }
    }
  }
`

export const MobileNavItemWrapper = styled.div`
  border-bottom: 1px solid #f2f4f7;
  button {
    width: 100%;
    border: none;
    background: transparent;
  }
  .mobile-nav-item--container {
    .mobile-nav-item--link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      padding: 18px 60px;
      a {
        text-decoration: none;
        color: #1b1b1b;
        &:hover {
          color: #002dc2;
        }
      }
      @media (max-width: 992px) {
        padding: 18px 40px;
      }
      @media (max-width: 576px) {
        padding: 18px 35px;
      }
      .mobile-nav-item--link-arrow {
        width: 11px;
        height: 6px;
        position: relative;
        img {
          width: 11px;
          opacity: 1;
          visibility: visible;
          position: absolute;
          transition: all 0.1s ease-in-out 0.1s;
          &.item-link-arrow-hover {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
    .mobile-nav-item--list {
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      .mobile-nav-item--list-main {
        .bottom-link {
          border: 1px solid #002dc2;
          width: 100%;
          border-radius: 40px;
          margin-bottom: 30px;

          @media (min-width: 768px) {
            width: fit-content;
          }
          & button {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #002dc2;
            text-decoration: none;
            padding: 8px 16px !important;
            height: 38px !important;
            &:hover {
              color: #5260ff;
            }
          }
        }
      }
      .mobile-nav-item--list-main,
      .mobile-nav-item--list-other-wrapper {
        padding: 0 60px;
        @media (max-width: 992px) {
          padding: 0 40px;
        }
        @media (max-width: 576px) {
          padding: 0 35px;
        }
      }
      .mobile-nav-item--list-description {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
      }
      .mobile-nav-item--list-content {
        margin: 30px 0 0;
        .mobile-nav-item--list-content-wrapper {
          display: flex;
          @media (max-width: 576px) {
            flex-direction: column;
          }
          .mobile-nav-item--list-category {
            flex: 1;
            padding: 0 55px;
            margin-bottom: 10px;
            &.no-title {
              padding-top: 67px;
              @media (max-width: 576px) {
                padding-top: 0px;
              }
            }
            @media (max-width: 768px) {
              padding: 0 30px;
            }
            @media (max-width: 576px) {
              padding: 0;
              max-width: 300px;
            }
            &:first-child {
              padding-left: 0;
            }
            h6 {
              font-weight: 900;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 2px;
              text-transform: uppercase;
              color: #4d4d4d;
              padding-bottom: 20px;
              border-bottom: 1px solid #e4e7ec;
              margin: 0 0 20px;
            }
          }
          .mobile-nav-item--list-menu-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            .nav-menu-item--wrapper {
              width: 50%;
            }
            @media (max-width: 576px) {
              flex-direction: column;
              .nav-menu-item--wrapper {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
      .mobile-nav-item--list-other {
        background: #f9fafb;
        .mobile-nav-item--list-other-wrapper {
          display: flex;
          padding-top: 40px;
          padding-bottom: 40px;
        }
        .mobile-nav-item--other-list {
          flex: 1;
          padding: 0 15px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
        @media (max-width: 576px) {
          .mobile-nav-item--list-other-wrapper {
            flex-direction: column;
          }
          .mobile-nav-item--other-list {
            padding: 15px 0;
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
        .mobile-nav-item--other-title {
          display: block;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #4d4d4d;
          margin: 0 0 20px;
        }
        img {
          width: 100%;
          height: auto;
          margin: 0 0 10px;
        }
        h6 {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #1b1b1b;
          padding: 4px 0;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: #667085;
          margin: 0;
        }
        .mobile-nav-item--other-learn-more {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #002dc2;
          text-decoration: none;
          margin: 4px 0 0;
        }
        .mobile-nav-item--other-link {
          display: block;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #101828;
          text-decoration: none;
          margin: 0 0 8px;
          &:hover {
            opacity: 0.7;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  &.nav-item--opened {
    .mobile-nav-item--container {
      .mobile-nav-item--link {
        color: #002dc2;
        .mobile-nav-item--link-arrow {
          img {
            opacity: 0;
            visibility: hidden;
            &.item-link-arrow-hover {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      .mobile-nav-item--list {
        max-height: 2000px;
      }
    }
  }
`

export const NavMenuItemWrapper = styled.div`
  margin-bottom: 20px;
  .nav-menu-item--link {
    display: flex;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
  .nav-menu-item--icon {
    margin-right: 14px;
    height: 38px;
    img {
      width: 38px;
      height: 38px;
    }
  }
  .nav-menu-item--content {
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #1b1b1b;
    }
  }
`
