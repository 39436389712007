import styled from "styled-components";

import { color } from "../../../atoms/colors";
import { Link } from "gatsby";
import { font } from "../../../atoms/typography";

export const SimpleFooterWrapper = styled.div`
  background-color: ${color.common.powerGrid};
  padding: 32px 30px 48px;
  color: ${color.grey[200]};
  font-family: "Roboto";
`

export const SimpleFooterContent = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    gap: 32px;
  }
`

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px; 
    path {
      transition: fill ease 150ms;
    }
    :hover {
      path {
        fill: ${color.primary[100]};
      }
    }
  }
`

export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${font("text", "xs", "400")}
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const LegalLinks = styled.div`
  display: flex;
  column-gap: 30px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .ot-sdk-show-settings {
    &#ot-sdk-btn {
      border: none;
      background: transparent;
      color: ${color.common.footerLink};
      width: max-content;
      text-decoration: none;
      padding: 0px;
      ${font("text", "xs", "400")}
      transition: color ease 150ms;
      :hover {
        background: transparent;
        color: ${color.primary[100]};
      }
    }
  }
`

export const Copyright = styled.span`
  color: #e4e7ec;
`

export const LegalLink = styled(Link) `
  color: ${color.common.footerLink};
  text-decoration: none;
  transition: color ease 150ms;
  :hover {
    color: ${color.primary[100]};
  }
`