// @see https://blog.teknkl.com/destyle-a-marketo-form/
const destyleMktoForm = (mktoForm) => {
	const formEl = mktoForm.getFormElem()[0];
	const arrayify = getSelection.call.bind([].slice);

	const styledEls = arrayify(formEl.querySelectorAll('[style]')).concat(formEl);
	styledEls.forEach((el) => {
		el.removeAttribute('style');
	});

	const styleSheets = arrayify(document.styleSheets);
	styleSheets.forEach((ss) => {
		if (
			[mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) !== -1 ||
			formEl.contains(ss.ownerNode)
		) {
			ss.disabled = true;
		}
	});
};

export default destyleMktoForm;