import { navigate } from "gatsby"
import React, { useState } from "react"
import {
  Section,
  Wrapper,
  Form,
  Input,
  SubmitBtn,
} from "../styles/SearchBar.styled"

const SearchBar = ({ searchActive }) => {
  const [value, setValue] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    setValue(value)
    navigate("/global-search", { state: { value: value } })
  }
  const handleChange = e => {
    setValue(e.target.value)
  }

  return (
    <Section searchActive={searchActive}>
      <Wrapper searchActive={searchActive}>
        <Form onSubmit={e => handleSubmit(e)}>
          <Input tabIndex={searchActive ? 0 : -1} type="text" value={value} onChange={e => handleChange(e)} />
          <SubmitBtn tabIndex={searchActive ? 0 : -1} type="submit" value="Submit" />
        </Form>
      </Wrapper>
    </Section>
  )
}

export default SearchBar
