import React, { useEffect, useState } from "react"
import destyleMktoForm from "../../utils/deStyleMarketo";
import LoadingAnimation from "./LoadingState";
import setScript from "../../utils/setScript";
import { HeroFormContainer, ConversionPanelFormContainer, BlogFormContainer, FooterFormContainer, PopUpFormContainer, PodcastHeroForm } from "./styles/MtkoForm.styled";

const MktoForm = ({ formId, background, setSubmitted, component, onClose }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [ready, setReady] = useState();

  const marketoId = formId ? formId : component?.marketoFormId
  const formBackground = background ? background : component?.background
  const type = component?.type
  const internalName = component?.internalName
  const jsSnippet = component?.javascriptSnippet?.javascriptSnippet
  const anchorLinkId = component?.anchorLinkId

  useEffect(() => {
    if (window.MktoForms2) return setScriptLoaded(true);
    const script = document.createElement('script');
    script.src = '//app-ab39.marketo.com/js/forms2/js/forms2.js';
    script.onload = () => setScriptLoaded(true);
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (!formLoaded && scriptLoaded && window.MktoForms2) {
      window.MktoForms2.loadForm("//app-ab39.marketo.com", process.env.GATSBY_MARKETO_ID, marketoId, (form) => {
        setFormLoaded(true);
        destyleMktoForm(form);
        setScript(jsSnippet)
      })

      window.MktoForms2.whenReady((form) => {
        let values = form.vals();

        if (values.honeypot) {
          if (values.honeypot === "") {
            form.submittable(true);
          } else {
            form.submittable(false);
          }
        }

        form.onValidate(function () {
          let vals = form.vals();
          let id = form.getId()
          const domains = ["gmail", "yahoo", "icloud", "aol", "hotmail", "att", "xyz", "mailinator"];
          const blacklisted = domains.filter((domain) => {
            const splitEmail = vals.Email.split("@");
            return splitEmail[1].includes(domain)
          })

          if (id === 1 || id === 1014) {
            form.submittable(true);
          } else {
            if (blacklisted.length > 0) {
              var emailElem = form.getFormElem().find("#Email");
              form.submittable(false);
              form.showErrorMessage("Please enter a business email", emailElem);

            } else {
              form.submittable(true);
            }
          }

        });

        {
          setSubmitted &&
            form.onSuccess(() => {
              console.log('success');
              setSubmitted(true);
              return false
            })
        }

        {
          type === "modal" &&
            form.onSuccess(() => {
              sessionStorage.setItem("form-submitted", "true");
              return true
            })
        }
      }
      )
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), 1500)
    return () => clearTimeout(timer)
  }, [scriptLoaded])

  switch (type) {
    case "modal":
      return (
        <PopUpFormContainer background={formBackground}>
          {!ready && <LoadingAnimation background={"white"} />}
          {scriptLoaded &&
            <form id={`mktoForm_${marketoId}`} style={{ width: '100%' }}></form>
          }
        </PopUpFormContainer>
      )
    case "conversion-panel":
      return (
        <ConversionPanelFormContainer background={formBackground} id={anchorLinkId}>
          {!ready && <LoadingAnimation background={"white"} />}
          {scriptLoaded &&
            <div className="wrapper">
              <form id={`mktoForm_${marketoId}`} style={{ width: '100%' }}></form>
            </div>
          }
        </ConversionPanelFormContainer>
      )
    case "footer":
      return (
        <FooterFormContainer id={anchorLinkId}>
          {!ready && <LoadingAnimation background={"blue"} />}
          {scriptLoaded && <form id={`mktoForm_${marketoId}`} style={{ width: '100%' }}></form>}
        </FooterFormContainer>
      )
    case "podcast":
      return (
        <PodcastHeroForm id={anchorLinkId}>
          {!ready && <LoadingAnimation background={"blue"} />}
          {scriptLoaded && <form id={`mktoForm_${marketoId}`} style={{ width: '100%' }}></form>}
        </PodcastHeroForm>
      )
    case "card": {
      return (
        <BlogFormContainer background={formBackground} id={anchorLinkId}>
          <div className="wrapper">
            {!ready && <LoadingAnimation background={"white"} />}
            {scriptLoaded && <form id={`mktoForm_${marketoId}`}></form>}
          </div>
        </BlogFormContainer>
      )
    }
    default:
      return (
        <HeroFormContainer background={formBackground} id={anchorLinkId}>
          {!ready && <LoadingAnimation background={"white"} />}
          {scriptLoaded && <form id={`mktoForm_${marketoId}`}></form>}
        </HeroFormContainer>
      );
  }
}

export default MktoForm
