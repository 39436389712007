import React from 'react';
import { Link } from "gatsby"
import { theme } from '@theme';
import styled from 'styled-components';
import { ConditionalWrapper } from './ConditionalWrapper';

export const IconButton = ({ ctaVariant, ctaLink, children, ...props }) => {

  const buttonStyles = {
    padding: '12px 32px',
    height: '50px',
    minWidth: '180px',
    borderRadius: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
    transition: 'all 0.2s ease 0s',
    fontFamily: `${ theme.font.primary }`,
    ...props.style
  }

  const isDemo = typeof children === 'string' && children.toLowerCase().includes('demo');

  return (
    <>
      <ConditionalWrapper
        condition={ctaLink}
        wrapper={children => <Link tabIndex={-1} aria-label={children.props.name} style={{ outline: 'none', textDecoration: 'none' }} to={ctaLink}>{children}</Link>}
      >
        <CTA
          data-comp={IconButton.displayName}
          ctaVariant={ ctaVariant }
          theme={ theme }
          style={{ ...buttonStyles }}
          isDemo={isDemo}
          name={children}
          aria-label={children}
        >
          <div className="button--inside">
            {children && <div className="button--text">{children}</div>}
            {isDemo && <img className="button--calendar-icon" src='/icons/calendar-white.svg' alt="calendar icon" />}
          </div>
        </CTA>
      </ConditionalWrapper>
    </>
  );
};

IconButton.displayName = 'IconButton';

const CTA = styled.button`
${({ ctaVariant, theme, isDemo }) =>
`
  background: ${ theme.buttons[ctaVariant].backgroundColor || '#002DC2' };
  color: ${ theme.buttons[ctaVariant].textColor };
  border: ${ '1px solid ' + theme.buttons[ctaVariant].borderColor };
  transition: all 0.2s ease-in;

  .button--inside {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  ${isDemo && `
  .button--text {
    transform: translateX(13px);
    transition: transform 0.2s ease-in;
  }

  .button--calendar-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    opacity: 0;
    transform: rotate(-90deg);
    transition: all 0.2s ease-in;
  }
  `}
  
  &:hover {
    background-color: ${ theme.buttons[ctaVariant].backgroundColorHover };
    color: ${ theme.buttons[ctaVariant].textColorHover };
    border: ${ '1px solid ' + theme.buttons[ctaVariant].borderColorHover };

    ${isDemo && `
    .button--text {
      transform: translateX(0);
    }
    
    .button--calendar-icon {
      opacity: 1;
      transform: rotate(0deg);
    }
    `}
  }
  &:focus {
    background-color: ${ theme.buttons[ctaVariant].backgroundColorHover };
    color: ${ theme.buttons[ctaVariant].textColorHover };
    border: 1px solid orange;

    ${isDemo && `
    .button--text {
      transform: translateX(0);
    }
    
    .button--calendar-icon {
      opacity: 1;
      transform: rotate(0deg);
    }
    `}
  }
`}
`