import { useEffect, useState } from "react"

const useScreenResponsive = mobileBp => {
  const [mobileView, setMobileView] = useState(false)
  const breakpoint = mobileBp || 992

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= breakpoint) {
        setMobileView(true)
      } else {
        setMobileView(false)
      }
    }

    window.addEventListener("resize", handleResize, { passive: true })
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [mobileView])

  return mobileView
}

export default useScreenResponsive
