import React, { useState } from "react"
import { defaultResolveRichText } from "../../../utils/resolve-rich-text/defaultResolveRichText"
import { MobileNavItemWrapper } from "../styles/MobileNavigation.styled"
import { navigate } from "gatsby"

import OptimizedImage from "../../../utils/optimizedImage"
import MobileMenuItem from "./MobileMenuItem"
import onKeyDown from "../../../utils/onKeyDown"
import { IconButton } from "../../../atoms"

const MobileNavItem = ({
  title,
  description,
  url,
  menuCategories,
  menuItems,
  sidebarFeatured,
  sidebarLinks,
  bottomLink,
}) => {
  const [navItemOpened, setNavItemOpened] = useState(false)

  return (
    <MobileNavItemWrapper
      className={`mobile-nav-item--wrapper ${
        navItemOpened ? "nav-item--opened" : ""
      }`}
    >
      <div className="mobile-nav-item--container">
        <button
          className="mobile-nav-item--link"
          onClick={() => setNavItemOpened(!navItemOpened)}
          onKeyDown={e => onKeyDown(e, () => setNavItemOpened(!navItemOpened))}
          aria-label={`${navItemOpened ? "Collapse" : "Expand"} ${title}`}
          aria-expanded={navItemOpened}
        >
          <a
            href={url}
            aria-label={title}
            onKeyDown={e => onKeyDown(e, () => navigate(url))}
          >
            {title}
          </a>
          {(menuCategories?.length > 0 || menuItems?.length > 0) && (
            <div className="mobile-nav-item--link-arrow">
              <img src="/icons/nav-arrow-down.svg" alt="arrow down icon" />
              <img
                className="item-link-arrow-hover"
                src="/icons/nav-arrow-down-hover.svg"
                alt="arrow down hover icon"
              />
            </div>
          )}
        </button>
        {(menuCategories?.length > 0 || menuItems?.length > 0) &&
          navItemOpened && (
            <div className="mobile-nav-item--list">
              <div className="mobile-nav-item--list-main">
                <p className="mobile-nav-item--list-description">
                  {description}
                </p>
                <div className="mobile-nav-item--list-content">
                  <div className="mobile-nav-item--list-content-wrapper">
                    {menuCategories?.length > 0 &&
                      menuCategories?.map((categoryItem, index1) => (
                        <div
                          className={`mobile-nav-item--list-category ${
                            categoryItem?.hideTitle ? "no-title" : ""
                          }`}
                          key={index1}
                        >
                          {!categoryItem?.hideTitle && (
                            <h6>{categoryItem?.title}</h6>
                          )}
                          <div className="mobile-nav-item--list-category-content">
                            {categoryItem.menuItems?.length > 0 &&
                              categoryItem.menuItems?.map(
                                (menuItem, index2) => (
                                  <MobileMenuItem {...menuItem} key={index2} />
                                )
                              )}
                          </div>
                        </div>
                      ))}
                    {menuItems?.length > 0 && (
                      <div className="mobile-nav-item--list-menu-content">
                        {menuItems?.map((menuItem, index2) => (
                          <MobileMenuItem {...menuItem} key={index2} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {bottomLink && (
                  <div className="bottom-link">
                    <IconButton
                      ctaVariant={bottomLink.variant}
                      ctaLink={bottomLink?.link}
                    >
                      {bottomLink?.label}
                    </IconButton>
                  </div>
                )}
              </div>
              <div className="mobile-nav-item--list-other">
                <div className="mobile-nav-item--list-other-wrapper">
                  <div className="mobile-nav-item--other-list">
                    <span className="mobile-nav-item--other-title">
                      Features
                    </span>
                    <OptimizedImage
                      image={sidebarFeatured?.featuredImage?.gatsbyImageData}
                      src={sidebarFeatured?.featuredImage?.file?.url}
                      alt={
                        sidebarFeatured?.featuredImage?.description ||
                        sidebarFeatured?.featuredImage?.title ||
                        "featured image"
                      }
                    />
                    <h6>{sidebarFeatured?.heading}</h6>
                    {sidebarFeatured?.subheading && (
                      <p>
                        {defaultResolveRichText(sidebarFeatured?.subheading)}
                      </p>
                    )}
                    <a
                      className="mobile-nav-item--other-learn-more"
                      href={sidebarFeatured?.callsToAction[0]?.link}
                    >
                      {sidebarFeatured?.callsToAction[0]?.label}
                    </a>
                  </div>
                  <div className="mobile-nav-item--other-list">
                    <span className="mobile-nav-item--other-title">More</span>
                    {sidebarLinks &&
                      sidebarLinks?.map(link => {
                        return (
                          <a
                            className="mobile-nav-item--other-link"
                            href={link?.link}
                          >
                            {link?.label}
                          </a>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </MobileNavItemWrapper>
  )
}

export default MobileNavItem
