import React from 'react';
import { theme } from '@theme';
import styled from 'styled-components';

const textVariantObj = {
  dark: theme.textColor.body,
  light: theme.textColor.bodyLight,
  muted: theme.textColor.muted,
};

export const TextXL = ({
  children,
  textVariant,
  mb,
  pb,
  mt,
  pt,
  ...props
}) => {
  const atomStyles = {
    mb: theme.spacing[mb] || '0px',
    mt: theme.spacing[mt] || '0px',
    pb: theme.spacing[pb] || '0px',
    pt: theme.spacing[pt] || '0px',
    color: !textVariant ? theme.textColor.body : textVariantObj[textVariant],
  };

  return (
    <P 
      atomStyles={atomStyles} 
      theme={theme}
      data-comp={TextXL.displayName} 
      {...props}
    >
      {children}
    </P>
  );
};

TextXL.displayName = 'TextXL';

const P = styled.p`
  ${({ atomStyles, theme }) =>
  `
    margin-top: ${atomStyles.mt};
    margin-bottom: ${atomStyles.mb};
    padding-top: ${atomStyles.pt};
    padding-bottom: ${atomStyles.pb};
    color: ${atomStyles.color};
    font-family: ${theme.font.secondary};
    height: theme;
  `}
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 27px;
  }
`
