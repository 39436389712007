const onKeyDown = (event, action, target) => {
  const targetKey = target ?? ["Enter", " "]
  
  if (event.key === targetKey || targetKey.includes(event.key)) {
    event.preventDefault()
    action()
  }
}

export default onKeyDown
