import React from "react"

export default ({ color }) => {
  let fill
  switch (color) {
    case "white" :
      fill = "#fff";
      break
    case "blue" :
      fill = "#002DC2";
      break
    default : 
      fill = "#002DC2";
  }
  return (
    <svg width="80" height="70" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="logo1" className="logo" opacity="1" d="M58.3197 14.7229L51.7062 3.27001C50.7212 1.53329 48.5636 0.312897 46.5468 0.312897H33.3667C31.3498 0.312897 29.2391 1.53329 28.2072 3.27001L21.5937 14.7229C20.6088 16.4597 20.6088 18.9004 21.5937 20.6372L27.3161 30.635C27.6913 31.2452 27.6913 31.9962 27.3161 32.6064L22.0628 41.7594C21.6876 42.3696 21.0778 42.7451 20.3273 42.7451H15.4024C14.7926 42.7451 14.0422 43.1675 13.7607 43.6838L9.35175 51.3817C9.07033 51.898 9.07033 52.7429 9.35175 53.2592L13.7607 60.9571C14.0422 61.4734 14.8395 61.8959 15.4024 61.8959H24.3142C24.9239 61.8959 25.6744 61.4734 25.9558 60.9571L30.3648 53.2592C30.6463 52.7429 30.6463 51.898 30.3648 51.3817L27.8789 47.0634C27.5037 46.4532 27.5037 45.7022 27.8789 45.092L32.5224 37.0655C33.1791 35.986 33.1791 34.6248 32.5224 33.4982L24.3142 19.1821C23.8451 18.4311 23.8451 17.1168 24.3142 16.3658L30.9277 4.91284C31.3967 4.16183 32.4755 3.5047 33.4136 3.5047H46.6406C47.5318 3.5047 48.6575 4.16183 49.1265 4.91284L55.74 16.3658C56.209 17.1168 56.209 18.4311 55.74 19.1821L53.2541 23.4534C52.8788 24.0636 52.8788 24.8147 53.2541 25.4249L54.4736 27.5371L58.3666 20.731C59.3047 18.9474 59.3047 16.4597 58.3197 14.7229Z" fill={fill}/>
      <path id="logo2" className="logo" opacity="1" d="M78.3984 49.3638L71.7849 37.9109C70.7999 36.1742 68.6423 34.9538 66.6254 34.9538H54.8525C54.1489 34.9538 53.4922 34.5783 53.117 33.9681L47.9106 24.909C47.5354 24.2988 47.5354 23.5478 47.9106 22.9376L50.3966 18.6193C50.678 18.1029 50.678 17.2581 50.3966 16.7417L45.9876 9.04387C45.7061 8.52755 44.9088 8.1051 44.3459 8.1051H35.4341C34.8244 8.1051 34.0739 8.52755 33.7925 9.04387L29.3835 16.7417C29.1021 17.2581 29.1021 18.1029 29.3835 18.6193L33.7925 26.3171C34.0739 26.8335 34.8713 27.2559 35.4341 27.2559H40.4529C41.1564 27.2559 41.8131 27.6314 42.1883 28.2416L46.7849 36.2211C47.4416 37.3007 48.5673 38.0048 49.8337 38.0048H66.6723C67.5635 38.0048 68.6892 38.6619 69.1582 39.4129L75.7717 50.8658C76.2408 51.6168 76.2408 52.9311 75.7717 53.6821L69.1113 65.1351C68.6423 65.8861 67.5635 66.5432 66.6254 66.5432H53.3984C52.5073 66.5432 51.3816 65.8861 50.9125 65.1351L48.4266 60.8637C48.0514 60.2535 47.4416 59.878 46.6911 59.878H44.3459L48.239 66.684C49.224 68.4207 51.3816 69.6411 53.3984 69.6411H66.6254C68.5016 69.6411 70.7999 68.2799 71.7849 66.684L78.3984 55.2311C79.4302 53.5413 79.4302 51.0536 78.3984 49.3638Z" fill={fill}/>
      <path id="logo3" className="logo" opacity="1" d="M70.6094 51.3812L66.2004 43.6833C65.919 43.167 65.1216 42.7445 64.5588 42.7445H55.647C55.0372 42.7445 54.2867 43.167 54.0053 43.6833L51.5194 48.0486C51.1442 48.6588 50.5344 49.0343 49.7839 49.0343H40.4031C39.1836 49.0343 37.9641 49.6914 37.3543 50.8179L29.0054 65.181C28.5363 65.932 27.4575 66.5892 26.5195 66.5892H13.2456C12.3544 66.5892 11.2287 65.932 10.7597 65.181L4.14617 53.7281C3.67713 52.9771 3.67713 51.6628 4.14617 50.9118L10.7597 39.4589C11.2287 38.7078 12.3075 38.0507 13.2456 38.0507H18.1236C18.8272 38.0507 19.4838 37.6752 19.8591 37.065L21.0786 34.9528H13.3394C11.4632 34.9528 9.16492 36.314 8.17993 37.9099L1.56644 49.3628C0.581451 51.0995 0.581451 53.5403 1.56644 55.277L8.17993 66.73C9.11801 68.3728 11.4163 69.6871 13.3394 69.6871H26.5664C28.4425 69.6871 30.7408 68.3259 31.7258 66.73L37.495 56.6852C37.8703 56.075 38.48 55.6995 39.2305 55.6995H49.8777C50.5813 55.6995 51.238 56.075 51.6132 56.6852L54.0991 61.0035C54.3805 61.5198 55.1779 61.9423 55.7408 61.9423H64.6526C65.2623 61.9423 66.0128 61.5198 66.2942 61.0035L70.7032 53.3056C70.8908 53.0709 70.9377 52.6954 70.9377 52.3669C70.8439 51.9444 70.797 51.6159 70.6094 51.3812Z" fill={fill}/>
    </svg>
  )
}
