import React, { useState } from "react"
import { Link } from "gatsby"
import { theme } from "@theme"
import styled from "styled-components"
import videoIcon from "../assets/icons/video.svg"
import { ConditionalWrapper } from "./ConditionalWrapper"
import { VideoFrame } from "../styles/components/VideoFrame"
import ModalVideo from "react-modal-video"
import { getYoutubeId } from "../utils/getYoutubeId"

export const Button = ({
  ctaVariant,
  ctaType,
  ctaLink,
  children,
  ...props
}) => {
  const buttonStyles = {
    padding: "12px 32px",
    height: "50px",
    minWidth: "180px",
    borderRadius: "40px",
    fontSize: "16px",
    cursor: "pointer",
    outline: "none",
    textDecoration: "none",
    transition: "all 0.2s ease 0s",
    fontFamily: `${theme.font.primary}`,
    ...props.style,
  }

  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [videoRef, setVideoRef] = useState(null)

  const playVideo = (videoUrl, reference) => {
    setVideo(getYoutubeId(videoUrl))
    setVideoRef(reference)
    setPlay(true)
  }

  return (
    <>
      {ctaType === "triggers-video-modal" ? (
        <CTA
          data-comp={Button.displayName}
          ctaVariant={ctaVariant}
          theme={theme}
          style={{ ...buttonStyles }}
          onClick={() => playVideo(ctaLink, props?.reference)}
        >
          <div className="button--inside">
            <div className="button--text video--text">
              <span>{children}</span>
              <img
                src={videoIcon}
                width={25}
                height={25}
                alt="Video Play Link Icon"
              />
            </div>
          </div>
        </CTA>
      ) : (
        <ConditionalWrapper
          condition={ctaLink}
          wrapper={children => (
            <Link
              tabIndex={-1}
              aria-label={children.props.name}
              style={{ outline: "none", textDecoration: "none" }}
              to={ctaLink}
            >
              {children}
            </Link>
          )}
        >
          <CTA
            data-comp={Button.displayName}
            ctaVariant={ctaVariant}
            theme={theme}
            style={{ ...buttonStyles }}
            tabIndex={0}
            name={children}
            aria-label={children}
          >
            <div className="button--inside">
              <div className="button--text">{children}</div>
            </div>
          </CTA>
        </ConditionalWrapper>
      )}
      {typeof window !== "undefined" && (
        <VideoFrame>
          <ModalVideo
            channel="youtube"
            isOpen={play}
            videoId={videoId}
            onClose={() => setPlay(false)}
            autoplay={true}
            reference={videoRef}
          />
        </VideoFrame>
      )}
    </>
  )
}

Button.displayName = "Button"

const CTA = styled.button`
  ${({ ctaVariant, theme, isDemo }) =>
    `
  background: ${theme.buttons[ctaVariant].backgroundColor || "#002DC2"};
  color: ${theme.buttons[ctaVariant].textColor};
  border: ${"1px solid " + theme.buttons[ctaVariant].borderColor};
  transition: all 0.2s ease-in;

  .button--inside {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button--text {
    img{
      vertical-align: middle;
      margin-left: 8px;
    }
    &.video--text {
      text-decoration: underline;
    }
  }
  &:hover {
    background-color: ${theme.buttons[ctaVariant].backgroundColorHover};
    color: ${theme.buttons[ctaVariant].textColorHover};
    border: ${"1px solid " + theme.buttons[ctaVariant].borderColorHover};
  }
  &:focus-visible {
    background-color: ${theme.buttons[ctaVariant].backgroundColorHover};
    color: ${theme.buttons[ctaVariant].textColorHover};
    border: 1px solid orange;
  }
`}
`
