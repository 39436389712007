import React from 'react';
import styled from 'styled-components'
import DesktopFooter from './DesktopFooter/DesktopFooter';
// import MobileFooter from './MobileFooter/MobileFooter';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Note: industry standard is to have Desktop Nav swap with Mobile Nav at 992px
export const Footer = () => {
  const data = useStaticQuery(graphql`
  query FooterQuery {
    allContentfulGlobalFooter {
      edges {
        node {
          id
          contactHeading
          contactSubheading
          contactEmail
          companyMeta {
            phoneNumber
            facebookUrl
            instagramUrl
            linkedInUrl
            twitterUrl
            youtubeUrl
          }
          navigationItems {
            description
            internalLink
            menuCategories {
              title
              id
              menuItems {
                description
                icon {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                  id
                }
                internalLink
                title
                url
                id
              }
            }
            menuItems {
              description
              icon {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                id
              }
              title
              url
              internalLink
              id
            }
            id
            title
            url
          }
          formId
          logos {
            primaryImage {
              gatsbyImageData
              id
              description
              file {
                url
              }
              
            }
          }
        }
      }
    }
  }
`)

  let footerData = data.allContentfulGlobalFooter?.edges[0]?.node;

  return (
    <nav
      data-comp={Footer.displayName}
    >
      <DesktopFooterWrapper>
        <DesktopFooter footerData={footerData} />
      </DesktopFooterWrapper>
    </nav>
  );
};

Footer.displayName = 'Footer';

const DesktopFooterWrapper = styled.div`

`