import styled from "styled-components"
import searchHeroImg from "../../../assets/images/search-hero-bg.svg"

export const Section = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 300ms ease-in-out, min-height 300ms ease-in-out,
    opacity 300ms ease-in-out;
  height: ${props => (props.searchActive ? "100%" : "0px")};
  min-height: ${props => (props.searchActive ? "370px" : "0px")};
  width: 100%;
  background-image: url(${searchHeroImg});
  opacity: ${props => (props.searchActive ? "1" : "0")};
  pointer-events: ${props => (props.searchActive ? "all" : "none")};
`

export const Wrapper = styled.div`
  transition: opacity 400ms ease-in;
  width: 100%;
  max-width: 571px;
  opacity: ${props => (props.searchActive ? "1" : "0")};
`
export const Form = styled.form`
  display: flex;
  width: 100%;
`

export const Input = styled.input`
  height: 40px;
  flex-basis: 80%;
  padding: 10px 0px 10px 20px;
  border-radius: 40px 0px 0px 40px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  transition: opacity 450ms ease-in-out;
`

export const SubmitBtn = styled.input`
  height: 40px;
  flex-basis: 20%;
  background: #002dc2;
  color: #fff;
  border-radius: 0px 40px 40px 0px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
`
