import React from "react";

import { SimpleHeaderContent, SimpleHeaderWrapper } from "./styles/SimpleHeader.styles";
import { Button } from "../../atoms/Button";
import WGLogo from "../../assets/icons/logo.svg"
import WGNoText from "../../assets/icons/logo-no-text.svg"
import { Link } from "gatsby";
import useScreenResponsive from "../../utils/screenResponsive";

const SimpleHeader = ({ simpleHeaderCta }) => {
  const isMobile = useScreenResponsive(400)

  return (
    <SimpleHeaderWrapper>
      <SimpleHeaderContent>
        <Link to="/" aria-label="Homepage">
          <img alt="Workgrid Logo" src={isMobile ? WGNoText : WGLogo} />
        </Link>
        {simpleHeaderCta &&
          <Button
            ctaVariant={simpleHeaderCta.variant}
            ctaType="standard"
            ctaLink={simpleHeaderCta.link}
          >
            {simpleHeaderCta.label}
          </Button>
        }
      </SimpleHeaderContent>
      {console.log(simpleHeaderCta)}
    </SimpleHeaderWrapper>
  )
}

export default SimpleHeader