import React from "react"
import styled from "styled-components"
import DesktopNavigation from "./DesktopNavigation/DesktopNavigation"
import MobileNavigation from "./MobileNavigation/MobileNavigation"
import { useStaticQuery, graphql } from "gatsby"

// Note: industry standard is to have Desktop Nav swap with Mobile Nav @ 992px
export const Header = ({ theme }) => {
  const navData = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulGlobalNavigation(
        filter: { id: { eq: "071c8dc5-624e-598d-bce8-64ea0766b8ed" } }
      ) {
        edges {
          node {
            id
            navigationItems {
              description
              internalLink
              bottomLink {
                label
                link
                variant
                type
              }
              menuCategories {
                title
                hideTitle
                id
                menuItems {
                  description
                  icon {
                    gatsbyImageData
                    file {
                      url
                    }
                    title
                    description
                    id
                  }
                  internalLink
                  title
                  url
                  id
                }
              }
              menuItems {
                description
                icon {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                  id
                }
                title
                url
                internalLink
                id
              }
              id
              title
              url
            }
            navigationCta {
              link
              internalName
              label
            }
            secondaryCta {
              link
              internalName
              label
            }
            sidebarFeatured {
              heading
              subheading {
                raw
              }
              callsToAction {
                label
                link
              }
              featuredImage {
                gatsbyImageData(width: 300)
                filename
                file {
                  url
                }
              }
            }
            sidebarLinks {
              ... on ContentfulComponentCta {
                id
                link
                label
              }
            }
          }
        }
      }
    }
  `)

  let navigationData = navData?.allContentfulGlobalNavigation?.edges[0]?.node
  return (
    <nav data-comp={Header.displayName}>
      <DesktopNavWrapper>
        <DesktopNavigation navigationData={navigationData} theme={theme} />
      </DesktopNavWrapper>

      <MobileNavWrapper>
        <MobileNavigation navigationData={navigationData} theme={theme} />
      </MobileNavWrapper>
    </nav>
  )
}

Header.displayName = "Header"

const DesktopNavWrapper = styled.div`
  display: none;
  @media (min-width: 1170px) {
    display: block;
  }
`
const MobileNavWrapper = styled.div`
  @media (min-width: 1170px) {
    display: none;
  }
`
