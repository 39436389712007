import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const HeroFormContainer = styled.div`
  width: 100%;
  ${({ background }) =>
    background === "white" &&
    `
    background: ${color.common.white};
    padding: 64px 48px;
    border: 1px solid ${color.grey[200]};
    box-shadow: 0px 20px 30px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    @media (max-width: 992px) {
      padding: 32px;
    }
  `}
  .mktoForm {
    width: 100% !important;
  }
  .mktoHasWidth {
    width: 100% !important;
  }
  .mktoFormRow {
    width: 100%;
    margin-bottom: 16px;
    label {
      display: inline-block;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 20px;
      color: ${color.grey[700]};
      margin: 0 0 6px;
    }
    .mktoRequiredField {
      label.mktoLabel {
        font-weight: 500 !important;
      }
    }
    input {
      width: 100%;
      height: 44px;
      background: ${color.common.white};
      border: 1px solid ${color.grey[300]};
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
      border-radius: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${color.grey[900]};
      padding: 10px 14px;
    }
    .mktoCheckboxList {
      display: flex;
      flex-direction: row;
      input {
        margin-right: 16px;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        background-color: ${color.common.backgroundLight};
        border: 1px solid ${color.grey[300]};
        border-radius: 4px;
        position: relative;
        margin-right: 16px;
      }
    }
    .mktoAsterix {
      display: none;
    }
    .mktoErrorMsg {
      font-size: 14px;
      color: ${color.grey[400]};
      margin-top: 8px;
    }
    .mktoHtmlText {
      font-size: 14px;
      font-weight: 500;
      color: ${color.grey[700]};
      a {
        text-decoration: none;
        color: #002dc2;
      }
    }
  }
  .mktoCaptchaDisclaimer {
    font-size: 14px;
    font-weight: 500;
    color: ${color.grey[700]};
    margin-bottom: 16px;
    a {
      text-decoration: none;
      color: #002dc2;
    }
  }
  select {
    height: 44px;
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
    appearance: none;
    background-image: url("/icons/nav-arrow-down.svg");
    background-position: center right 14px;
    background-repeat: no-repeat;
  }
  textarea {
    font-family: "Roboto";
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
  }
  .mktoButtonRow {
    button {
      color: ${color.common.white};
      padding: 12px 32px;
      height: 50px;
      min-width: 180px;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
      }
    }
  }
  .mktoForm .g-recaptcha {
    visibility: visible;
    width: 100%;
  }
  .g-recaptcha > DIV {
    width: 100% !important;
    height: fit-content;
  }
  .g-recaptcha > DIV > DIV {
    border-top: 12px solid transparent;
  }
`

export const ConversionPanelFormContainer = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  margin: 40px 15px;
  .wrapper {
    margin: 0 auto;
    max-width: 1170px;
    @media (max-width: 992px) {
      max-width: 770px;
    }
    @media (max-width: 768px) {
      max-width: 506px;
    }
    ${({ background }) =>
      background === "white" &&
      `
      background: ${color.common.white};
      padding: 64px 48px;
      border: 1px solid ${color.grey[200]};
      box-shadow: 0px 20px 30px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      @media (max-width: 992px) {
        padding: 32px;
      }
    `}
  }
  select {
    height: 44px;
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
    appearance: none;
    background-image: url("/icons/nav-arrow-down.svg");
    background-position: center right 14px;
    background-repeat: no-repeat;
  }
  textarea {
    font-family: "Roboto";
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
  }
  .mktoForm {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mktoHasWidth {
    width: 100% !important;
  }
  .mktoFormRow {
    display: contents;
    .mktoOffset {
      display: none;
    }
    label {
      display: inline-block;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 20px;
      color: ${color.grey[700]};
      margin: 0 0 6px;
    }
    .mktoFormCol {
      display: contents;
    }
    .mktoFieldWrap {
      width: 100%;
      .mktoHtmlText {
        width: 100% !important;
        margin-bottom: 8px;
      }
      &.mktoRequiredField {
        width: 48% !important;
        margin: 8px 0 8px;
        @media (max-width: 768px) {
          width: 100% !important;
        }
        label.mktoLabel {
          font-weight: 500 !important;
        }
      }
    }
    input {
      width: 100%;
      height: 44px;
      background: ${color.common.white};
      border: 1px solid ${color.grey[300]};
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
      border-radius: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${color.grey[900]};
      padding: 10px 14px;
    }
    .mktoCheckboxList {
      display: flex;
      flex-direction: row;
      input {
        margin-right: 16px;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        background-color: ${color.common.backgroundLight};
        border: 1px solid ${color.grey[300]};
        border-radius: 4px;
        position: relative;
        margin-right: 16px;
      }
    }
    .mktoAsterix {
      display: none;
    }
    .mktoErrorMsg {
      font-size: 14px;
      color: ${color.grey[400]};
      margin-top: 8px;
    }
    .mktoHtmlText {
      font-size: 14px;
      font-weight: 500;
      color: ${color.grey[700]};
      a {
        text-decoration: none;
      }
    }
  }

  .mktoButtonRow {
    margin-top: 16px;
    width: unset !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
    button {
      color: ${color.common.white};
      padding: 12px 32px;
      height: 50px;
      min-width: 180px;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      transition: all 0.2s ease 0s;
      font-family: Roboto, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
      }
    }
  }

  .mktoCaptchaDisclaimer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: ${color.grey[700]};
    a {
      text-decoration: none;
    }
  }

  .mktoForm .g-recaptcha {
    visibility: visible;
    width: 100%;
  }
  .g-recaptcha > DIV {
    width: 100% !important;
    height: fit-content;
  }
  .g-recaptcha > DIV > DIV {
    border-top: 12px solid transparent;
  }
`
export const FooterFormContainer = styled.div`
  width: 100%;
  max-width: 370px;
  margin: 40px 0 0;
  .mktoAsterix {
    display: none;
  }
  .mktoButtonRow {
    margin-top: 16px;
    text-align: left;
    button {
      color: ${color.common.white};
      padding: 12px 32px;
      height: 50px;
      width: 100%;
      max-width: 180px;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      transition: all 0.2s ease 0s;
      font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
      }
    }
  }
  .mktoFormRow {
    p {
      font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      font-display: swap;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: ${color.common.white};
      margin-bottom: 16px;
    }
    label#LblEmail {
      display: none;
    }
    input#Email {
      width: 100%;
      background: ${color.common.white};
      border: 1px solid ${color.common.disabled};
      border-radius: 40px;
      margin-bottom: 9px;
      padding: 9px 20px;
      &:focus,
      &:active {
        border: 1px solid #9378d3;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ede8f7;
      }
    }
    .mktoHtmlText {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${color.common.disabled};
      a {
        color: #969eff !important;
        text-decoration: none;
      }
    }
    .mktoLogicalField {
      display: flex;
      align-items: center;
      padding-bottom: 9px;
      input {
        width: 18px;
        height: 18px;
        background-color: ${color.common.backgroundLight};
        border: 1px solid ${color.grey[300]};
        border-radius: 4px;
      }
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${color.common.disabled};
        margin-left: 16px;
      }
    }
    .mktoErrorMsg {
      font-size: 14px;
      color: ${color.grey[400]};
      margin: 8px 0px 12px 0px;
    }
  }
  .mktoCaptchaDisclaimer {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${color.common.disabled};
    a {
      color: #969eff;
      text-decoration: none;
    }
  }
`

export const BlogFormContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
  }
  border-radius: 8px;
  padding: 24px;
  background: linear-gradient(108deg, #a7dbeb 0%, #9a97e0 100%);
  .wrapper {
    background: ${color.common.backgroundLight};
    border-radius: 4px;
    box-shadow: 0px 12px 20px 5px rgba(27, 27, 27, 0.15);
    height: 100%;
    color: ${color.grey[700]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 26px;
    a {
      color: ${color.common.workgridBlue};
      transition: color ease 150ms, text-decoration ease 150ms;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: ${color.common.hoverBlue};
      }
    }
    .mktoLabel {
      font-weight: 500;
      color: ${color.grey[700]};
    }
    .mktoHtmlText {
      font-size: 14px;
      color: ${color.grey[700]};
      strong {
        font-size: 22px;
        line-height: 30px;
        color: ${color.common.black};
      }
    }
    .mktoError {
      margin-top: 12px;
      .mktoErrorMsg,
      span {
        font-size: 12px;
        color: ${color.common.emphasisPink};
      }
    }
    input {
      margin-top: 10px;
      padding: 8px 20px;
      border-radius: 35px;
      height: 40px;
      border: 1.5px solid ${color.grey[300]};
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-size: 16px;
      outline: none;
      width: 100%;
      :hover {
        border: 1.5px solid ${color.common.hoverBlue}!important;
      }
      :active,
      :focus-within,
      :focus-visible {
        border: 1.5px solid ${color.common.workgridBlue};
        box-shadow: 0px 0px 0px 3px #c8c8ff,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
    .mktoCheckboxList {
      display: flex;
      label {
        font-size: 14px;
        cursor: pointer;
        color: ${color.grey[700]};
      }
    }
    input[type="checkbox"] {
      padding: 0;
      border-radius: 1.5px;
      position: relative;
      cursor: pointer;
      appearance: none;
      background-color: ${color.common.white};
      box-shadow: none;
      margin-top: 2px;
      height: 20px;
      min-width: 20px;
      width: unset;
      margin-right: 12px;
    }
    input[type="checkbox"]:checked {
      background-color: ${color.common.workgridBlue};
      border-color: ${color.common.workgridBlue};
      ::after {
        position: absolute;
        content: "";
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border-style: solid;
        border-color: ${color.common.white};
        border-image: initial;
        border-width: 0px 1.5px 1.5px 0px;
        transform: rotate(45deg);
      }
    }
    .mktoButtonRow {
      width: 100% !important;
      text-align: left;
    }
    button {
      color: white;
      background-color: ${color.common.workgridBlue};
      padding: 12px 32px;
      height: 50px;
      min-width: 180px;
      width: 100%;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      transition: background-color 0.2s ease 0s;
      width: 100% !important;
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
        &:hover {
          background: ${color.common.hoverBlue} !important;
        }
      }
    }
    .mktoFormRow {
      margin-bottom: 16px;
    }
    .mktoAsterix {
      display: none;
    }
  }
  .mktoCaptchaDisclaimer {
    margin-top: 8px;
    font-size: 14px;
    color: ${color.grey[700]};
    margin-bottom: 16px;
    strong {
      font-size: 22px;
      line-height: 30px;
      color: ${color.common.black};
    }
  }
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  .logo {
    animation: fadein 3.3s infinite;
    opacity: 0;
    transform-origin: 50% 50%;
  }
  .logo:nth-child(2) {
    animation-delay: 0.2s;
  }
  .logo:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes fadein {
    0%,
    100% {
      transform: scale(0.001, 0.001);
      opacity: 0;
    }
    33%,
    66% {
      transform: scale(0.9, 0.9);
      opacity: 1;
    }
  }
`

export const LoadingDescription = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 16px;
  border-radius: 20px;
  ${({ fontColor }) =>
    fontColor === "white"
      ? `
      color: ${color.common.white};
      border: 1px solid ${color.common.white};
    `
      : `
      color: ${color.common.workgridBlue};
      border: 1px solid ${color.common.workgridBlue};
    `}
`

export const PopUpFormContainer = styled.div`
  * {
    font-family: Roboto, sans-serif;
  }
  position: relative;
  a {
    color: ${color.common.hoverBlue};
  }
  h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    color: ${color.grey[900]};
    margin-bottom: 16px;
    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
  margin: 0 auto;
  max-width: 770px;
  @media (max-width: 768px) {
    max-width: 506px;
  }
  ${({ background }) =>
    background === "white" &&
    `
    background: ${color.common.white};
    padding: 64px 63px;
    border: 1px solid ${color.grey[200]};
    box-shadow: 0px 20px 30px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    @media (max-width: 576px) {
      padding: 32px;
    }
  `}
  select {
    height: 44px;
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
    appearance: none;
    background-image: url("/icons/nav-arrow-down.svg");
    background-position: center right 14px;
    background-repeat: no-repeat;
  }
  textarea {
    background: ${color.common.white};
    border: 1px solid ${color.grey[300]};
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color.common.powerGrid};
    padding: 10px 14px;
  }
  .mktoForm {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mktoHasWidth {
    width: 100% !important;
  }
  .mktoFormRow {
    display: contents;
    .mktoOffset {
      display: none;
    }
    label {
      display: inline-block;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 20px;
      color: ${color.grey[700]};
      margin: 0 0 6px;
    }
    .mktoFormCol {
      display: contents;
    }
    .mktoFieldWrap {
      width: 100%;
      .mktoHtmlText {
        width: 100% !important;
        margin-bottom: 8px;
        color: #475467;
        font-weight: 400;
      }
      .mktoCaptchaDisclaimer {
        color: #475467;
        font-weight: 400;
      }
      &.mktoRequiredField {
        margin: 8px 0 8px;
        @media (max-width: 768px) {
          width: 100% !important;
        }
        label.mktoLabel {
          font-weight: 500 !important;
        }
      }
    }
    input {
      width: 100%;
      height: 44px;
      background: ${color.common.white};
      border: 1px solid ${color.grey[300]};
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
      border-radius: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${color.grey[900]};
      padding: 10px 14px;
    }
    .mktoCheckboxList {
      display: flex;
      flex-direction: row;
      input {
        margin-right: 16px;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        background-color: ${color.common.backgroundLight};
        border: 1px solid ${color.grey[300]};
        border-radius: 4px;
        position: relative;
        margin-right: 16px;
      }
      label {
        font-weight: 400 !important;
      }
    }
    .mktoAsterix {
      display: none;
    }
    .mktoErrorMsg {
      font-size: 14px;
      color: ${color.grey[400]};
      margin-top: 8px;
    }
    .mktoHtmlText {
      font-size: 14px;
      font-weight: 500;
      color: ${color.grey[700]};
      a {
        text-decoration: none;
      }
    }
  }
  .mktoButtonRow {
    margin-top: 16px;
    @media (max-width: 768px) {
      width: 100% !important;
    }
    button {
      color: ${color.common.white};
      padding: 12px 32px;
      min-height: 47px;
      min-width: 180px;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      :hover {
        opacity: 0.8;
      }
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
      }
    }
  }

  .mktoCaptchaDisclaimer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: ${color.grey[700]};
    a {
      text-decoration: none;
    }
  }
  .mktoForm .g-recaptcha {
    visibility: visible;
    width: 100%;
  }
  .g-recaptcha > DIV {
    width: 100% !important;
    height: fit-content;
  }
  .g-recaptcha > DIV > DIV {
    border-top: 12px solid transparent;
  }
`

export const PodcastHeroForm = styled.div`
  * {
    font-family: Roboto, sans-serif;
  }
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  position: relative;
  color: ${color.common.white};
  .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
    background: none !important;
    border: 1px solid white !important;
  }
  form {
    width: 100% !important;
    text-align: left;
    .mktoAsterix {
      display: none;
    }
    .mktoHasWidth {
      width: 100% !important;
    }
    a {
      color: ${color.electricBlue[200]};
    }
    h2 {
      display: none;
    }
  }
  .mktoButtonRow {
    text-align: left;
    .mktoButton,
    button {
      max-width: unset;
      margin-top: 12px;
      @media (min-width: 577px) {
        position: absolute;
        max-width: 150px;
        margin-top: 0;
      }
      right: 0;
      top: 0;
      color: ${color.common.white};
      background: transparent !important;
      padding: 12px 32px;
      height: 50px;
      width: 100%;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid white !important;
      text-decoration: none;
      transition: all 0.2s ease 0s;
      font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      &.mktoButton {
        &:focus,
        &:focus-visible {
          border: 2px solid orange !important;
        }
      }
    }
  }
  .mktoFormRow {
    p {
      font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
      font-display: swap;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: ${color.common.white};
      margin-bottom: 16px;
    }
    label#LblEmail {
      display: none;
    }
    input#Email {
      @media (min-width: 577px) {
        width: calc(100% - 170px) !important;
      }
      height: 50px;
      background: ${color.common.white};
      border: 1px solid ${color.common.disabled};
      border-radius: 40px;
      margin-bottom: 9px;
      padding: 9px 20px;
      &:focus,
      &:active {
        border: 1px solid #9378d3;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ede8f7;
      }
    }
    .mktoHtmlText,
    .mktoCaptchaDisclaimer {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .mktoLogicalField {
      display: flex;
      padding-bottom: 9px;
      input {
        width: 18px;
        height: 18px;
        background-color: ${color.common.backgroundLight};
        border: 1px solid ${color.grey[300]};
        border-radius: 4px;
      }
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-left: 16px;
      }
    }
    .mktoErrorMsg {
      font-size: 14px;
      color: ${color.common.white};
      margin: 8px 0px 12px 0px;
    }
  }
`
