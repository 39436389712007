import styled from "styled-components";

import { color } from "../../../atoms/colors";

export const SimpleHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100px;
  background: ${props => props.theme?.background || color.common.white};
  padding: 0 30px;
`

export const SimpleHeaderContent = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    font-weight: bold;
    padding: 9px 29px !important;
    height: auto !important;
    font-weight: bold;
    line-height: 27px !important;
    max-width: 204px;
  }
  img {
    width: 170px;
    height: auto;
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 576px) {
    img {
      width: auto;
    }
  }
`