import styled, { css } from "styled-components"

/*
 * usage: ${color.primary[600]}
 */
export const color = {
  common: {
    white: "#fff",
    black: "#1b1b1b",
    electricBlue: "#69D1F0",
    workgridBlue: "#002DC2",
    cosmicGrid: "#090069",
    powerGrid: "#050036",
    lightBlue: "#F1FBFF",
    nobiBlue: "#95F1E1",
    hoverBlue: "#5260FF",
    emphasisPink: "#E80065",
    disabled: "#cccccc",
    footerLink: "#767fff",
    backgroundLight: "#F7F8FC",
    transparent: "transparent",
  },
  primary: {
    50: "#ECE8FA",
    100: "#CEC6F3",
    200: "#AEA0EC",
    300: "#8B78E5",
    400: "#6E5BDF",
    500: "#4D3FD9",
    600: "#403BD2",
    700: "#2933C9",
    800: "#002DC2",
    900: "#0022B7",
  },
  grey: {
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#E4E7EC",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  restfulGrey: {
    50: "#EBEEF3",
    100: "#CED6DE",
    200: "#B0BAC7",
    300: "#919FAF",
    400: "#7A8A9D",
    500: "#63768C",
    600: "#56687B",
    700: "#465465",
    800: "#37424F",
    900: "#252D38",
  },
  electricBlue: {
    50: "#DDF4FB",
    100: "#A8E3F5",
    200: "#69D1F0",
    300: "#00BEEB",
    400: "#00B0E9",
    500: "#00A3E7",
    600: "#0095DA",
    700: "#0082C8",
    800: "#0072B5",
    900: "#005295",
  },
}

/*
 * usage: ${buttonThemes.primary}
 */
export const buttonThemes = {
  primary: css`
    background: #002dc2;
    color: #ffffff;
    border-color: #002dc2;
    text-decoration: none;
    padding: 16px 32px;
    text-align: center;
    border-radius: 40px;
    transition: background-color 0.2s ease-in;
    &:hover {
      background: #5260ff;
      color: #ffffff;
      border-color: unset;
    }
    &.disabled {
      background: #cccccc;
      color: #ffffff;
      border-color: unset;
    }
  `,
  primaryLight: css`
    background: #ffffff;
    color: #002dc2;
    border-color: unset;
    text-decoration: none;
    text-align: center;
    padding: 16px 32px;
    border-radius: 40px;
    &:hover {
      background: #5260ff;
      color: #ffffff;
      border-color: unset;
    }
    &.disabled {
      background: #cccccc;
      color: #002dc2;
      border-color: unset;
    }
  `,
  secondary: css`
    background: transparent;
    color: #002dc2;
    border-color: #002dc2;
    text-decoration: none;
    text-align: center;
    padding: 16px 32px;
    border-radius: 40px;
    &:hover {
      background: transparent;
      color: #5260ff;
      border-color: #5260ff;
    }
    &.disabled {
      background: #ffffff;
      color: #cccccc;
      border-color: #cccccc;
    }
  `,
  secondaryLight: css`
    background: transparent;
    color: #ffffff;
    border-color: #ffffff;
    text-decoration: none;
    text-align: center;
    padding: 16px 32px;
    border-radius: 40px;
    &:hover {
      background: transparent;
      color: #c8c8ff;
      border-color: #c8c8ff;
    }
    &.disabled {
      background: transparent;
      color: #cccccc;
      border-color: #cccccc;
    }
  `,
  link: css`
    color: #002dc2;
    &:hover {
      color: #5260ff;
    }
    &.disabled {
      color: #cccccc;
    }
  `,
  linkLight: css`
    color: #ffffff;
    &:hover {
      color: #c8c8ff;
    }
    &.disabled {
      color: #cccccc;
    }
  `,
}
