import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import Hamburger from "hamburger-react"
import { IconButton } from "@atoms"
import { Link } from "gatsby"
import { useIsScrolled } from "@hooks/useIsScrolled"
import {
  MobileNavWrapper,
  NavMenuContainer,
} from "../styles/MobileNavigation.styled"

import WGLogo from "@assets/icons/logo.svg"
import WGLogoWhite from "@assets/icons/logo-white.svg"
import MobileNavItem from "../components/MobileNavItem"
import { StaticImage } from "gatsby-plugin-image"
import onKeyDown from "../../../utils/onKeyDown"

const MobileNavigation = ({ navigationData, theme = { theme: "light" } }) => {
  const [filteredTheme, setFilteredTheme] = useState({ ...theme })
  const [menuOpened, setMenuOpened] = useState(false)
  const [value, setValue] = useState("")
  const { isScrolled } = useIsScrolled()

  const handleSubmit = e => {
    e.preventDefault()
    setValue(value)
    navigate("/global-search", { state: { value: value } })
  }

  const handleChange = e => {
    setValue(e.target.value)
  }

  const sidebarFeatured = navigationData?.sidebarFeatured
  const sidebarLinks = navigationData?.sidebarLinks

  useEffect(() => {
    if (menuOpened) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [menuOpened])

  useEffect(() => {
    if (isScrolled) {
      setFilteredTheme({ theme: "light" })
    } else {
      setFilteredTheme({ ...theme })
    }
  }, [isScrolled])

  return (
    <MobileNavWrapper
      data-comp={MobileNavigation.displayName}
      theme={filteredTheme}
      menuOpened={menuOpened}
    >
      <div className="nav--container">
        <div className="nav--left">
          <Link to="/" aria-label="Homepage">
            <img
              className="nav--logo"
              src={
                filteredTheme?.theme === "dark" && menuOpened === false
                  ? WGLogoWhite
                  : WGLogo
              }
              alt="workgrid header logo"
            />
          </Link>
        </div>
        <div className="nav--right">
          <IconButton
            ctaVariant={
              filteredTheme?.theme === "dark" ? "secondary" : "primary"
            }
            ctaLink={navigationData?.navigationCta?.link}
          >
            {navigationData?.navigationCta?.label}
          </IconButton>
          <div className="nav--burger">
            <Hamburger
              size={24}
              color={
                filteredTheme?.theme === "dark" && menuOpened === false
                  ? "#FFF"
                  : "#1B1B1B"
              }
              label={
                menuOpened ? "Close Navigation Menu" : "Open Navigation Menu"
              }
              toggled={menuOpened}
              toggle={setMenuOpened}
            />
          </div>
        </div>
      </div>
      <NavMenuContainer
        className={`nav--menu-container ${
          menuOpened ? "nav--menu-opened" : ""
        }`}
      >
        <div className="nav--menu-container-wrapper">
          <div className="nav--menu-search-container">
            <div className="nav--menu-search-container-wrapper">
              <form onSubmit={e => handleSubmit(e)}>
                <input
                  placeholder="Search"
                  value={value}
                  onChange={e => handleChange(e)}
                />
                <div
                  role="button"
                  id="search-icon"
                  tabIndex={0}
                  onClick={e => handleSubmit(e)}
                  onKeyDown={e => onKeyDown(e, () => handleSubmit(e))}
                  aria-label={`Search for ${value}`}
                >
                  <img
                    src="/icons/nav-search.svg"
                    alt="navigation search icon"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="nav--menu-list-container">
            {navigationData?.navigationItems?.map((navItem, index) => (
              <MobileNavItem
                {...navItem}
                sidebarFeatured={sidebarFeatured}
                sidebarLinks={sidebarLinks}
                key={index}
                theme={filteredTheme}
              />
            ))}
          </div>
          <div className="nav--menu-footer-container">
            <div className="nav--menu-footer-container-wrapper">
              <StaticImage
                className="background"
                src="../../../../static/images/nav-mobile-footer.png"
              />
              <p>Want to see Workgrid in Action?</p>
              <div className="cta-wrapper">
                <IconButton
                  ctaVariant="secondary"
                  ctaLink={navigationData?.navigationCta?.link}
                >
                  {navigationData?.navigationCta?.label}
                </IconButton>
                {navigationData?.secondaryCta && (
                  <IconButton
                    ctaVariant="secondary"
                    ctaLink={navigationData.secondaryCta?.link}
                  >
                    {navigationData.secondaryCta?.label}
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </NavMenuContainer>
    </MobileNavWrapper>
  )
}

export default MobileNavigation
MobileNavigation.displayName = "MobileNavigation"
