import styled from "styled-components"
import { color } from "../../../atoms/colors"

export const FooterWrapper = styled.div`
  font-family: "Roboto";
  z-index: 11;

  .footer--top {
    padding: 96px 0;
    background: #090069;

    .footer--top-wrapper {
      max-width: 1226px;
      margin: 0 auto;
      padding: 0 28px;
      display: flex;
      justify-content: space-between;
    }

    .footer--top-left-content {
      width: 400px;
      margin-right: 15px;

      .footer--logo-content {
        img {
          width: 170px;
          height: auto;
        }
      }
    }

    .footer--top-left-content,
    .footer--top-mobile-content {
      .footer--contact-content {
        margin: 40px 0 0;
        display: flex;
        flex-direction: column;

        .heading {
          font-weight: 700;
          font-size: 28px;
          line-height: 34px;
          letter-spacing: 0.1px;
          color: #ffffff;
          margin: 0px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          opacity: 0.7;
          margin: 18px 0 12px;
        }

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin: 8px 0;
          text-decoration: none;

          img {
            margin-right: 16px;
            width: 18px;
            height: 27px;
          }
        }
      }

      .footer--search-content {
        margin: 40px 0 0;
        display: flex;
        align-items: center;

        a {
          display: block;
        }

        .footer--review-content {
          margin-right: 40px;

          img {
            width: 126px;
            height: 63px;
          }
        }

        .footer--mobile-app-content {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            opacity: 0.7;
          }

          .footer--app-icon-content {
            margin: 12px 0 0;
            display: flex;

            .footer--appstore-img {
              width: 101px;
              height: 34px;
            }

            .footer--googleplay-img {
              width: 114px;
              height: 34px;
              margin-left: 20px;
            }
          }
        }
      }
    }

    .footer--top-mobile-content {
      display: none;
    }

    .footer--top-right-content {
      width: 640px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;

      .footer--top-right-menu-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 410px;

        .footer--nav-menu {
          display: flex;
          flex-direction: column;
          padding: 0 20px 30px 0;

          a {
            text-decoration: none;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }

          .footer--nav-menu-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            margin-bottom: 20px;
          }

          .footer--nav-menu-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #e4e9f1;
            opacity: 0.9;
            margin-bottom: 6px;
          }
        }
      }

      .footer--top-right-logo-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 33px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        opacity: 0.6;
      }
    }
  }

  .footer--bottom {
    padding: 42px 0;
    background: #050036;

    a {
      display: block;

      &:hover {
        opacity: 0.8;
      }
    }

    .footer--bottom-wrapper {
      max-width: 1226px;
      margin: 0 auto;
      padding: 0 28px;
      display: flex;
      justify-content: space-between;
    }

    .footer--bottom-left-content {
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #e4e7ec;
        margin-right: 35px;
      }

      .footer--bottom-link-content {
        display: flex;
        align-items: center;

        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #767fff;
          text-decoration: none;
          margin-right: 30px;
          text-align: center;
          transition: color ease 150ms;
          :hover {
            color: ${color.primary[100]};
          }
        }
        #ot-sdk-btn {
          border: none;
          background: transparent;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #767fff;
          width: max-content;
          text-decoration: none;
          padding: 0px;
          transition: color ease 150ms;
          :hover {
            color: ${color.primary[100]};
          }
        }
      }
    }

    .footer--bottom-right-content {
      display: flex;
      align-items: center;

      .footer--social-link {
        margin-left: 14px;
      }

      svg {
        width: 24px;
        height: 24px;
        path {
          transition: fill ease 150ms;
        }
        :hover {
          path {
            fill: ${color.primary[100]};
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .footer--top {
      .footer--top-wrapper {
        max-width: 824px;
      }

      .footer--top-left-content {
        width: 300px;
        margin-right: 35px;

        .footer--subscribe-content {
          margin-top: 30px;

          .footer--subscribe-email-content {
            input {
              min-width: 0;
            }

            button {
              min-width: 115px;
            }
          }

          .footer--subscribe-check-content {
            margin: 18px 0 10px;
          }
        }

        .footer--contact-content {
          margin-top: 30px;
        }

        .footer--search-content {
          margin-top: 30px;

          .footer--review-content {
            display: none;
          }
        }
      }

      .footer--top-right-content {
        width: auto;
        flex: 1;
        margin-left: 35px;

        .footer--top-right-logo-content {
          display: none;
        }
      }
    }

    .footer--bottom {
      padding: 34px 0;

      .footer--bottom-wrapper {
        max-width: 824px;
      }

      .footer--bottom-left-content {
        flex-direction: column;
        align-items: flex-start;

        .footer--bottom-link-content {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 810px) {
    .footer--top {
      .footer--top-left-content {
        flex: 1;
        width: 45%;

        .footer--subscribe-content {
          .footer--subscribe-email-content {
            input {
              width: 60%;
            }
            button {
              width: 40%;
            }
          }
        }
      }

      .footer--top-right-content {
        flex: 1;
        width: 45%;

        .footer--top-right-menu-content {
          height: auto;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .footer--top-right-menu-content {
      .footer--nav-menu {
        width: 100%;
      }
    }
  }
  @media (max-width: 575px) {
    .footer--top {
      padding: 54px 0;

      .footer--top-wrapper {
        flex-direction: column;
        padding: 0 35px;
      }

      .footer--top-left-content {
        width: 100%;
        margin: 0;

        .footer--subscribe-content {
          .footer--subscribe-email-content {
            margin-top: 25px;

            input {
              width: auto;
            }
            button {
              width: 120px;
            }
          }

          .footer--subscribe-check-content {
            margin: 20px 0 12px;
          }
        }

        .footer--contact-content,
        .footer--search-content {
          display: none;
        }
      }

      .footer--top-right-content {
        width: 100%;
        margin: 40px 0 0;

        .footer--top-right-menu-content {

          .footer--nav-menu {
            width: 55%;
          }
        }
      }

      .footer--top-mobile-content {
        display: flex;
        margin-top: 30px;

        .footer--contact-content {
          margin: 0;
          width: 55%;
        }

        .footer--search-content {
          margin: 0;
          width: 40%;

          .footer--mobile-app-content {
            .footer--app-icon-content {
              flex-direction: column;

              .footer--googleplay-img {
                margin: 10px 0 0;
              }
            }
          }
        }
      }
    }

    .footer--bottom {
      padding: 40px 0;

      .footer--bottom-wrapper {
        flex-direction: column;
        align-items: center;
      }

      .footer--bottom-left-content {
        align-items: center;

        span {
          margin: 0;
        }

        .footer--bottom-link-content {
          a {
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .footer--bottom-right-content {
        margin: 20px 0 0;

        .footer--social-link {
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .footer--top {
      .footer--top-right-content {
        .footer--top-right-menu-content {
          height: auto;

          .footer--nav-menu {
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }

      .footer--top-mobile-content {
        flex-direction: column;

        .footer--contact-content,
        .footer--search-content {
          width: 100%;
          padding: 0;
        }

        .footer--search-content {
          margin-top: 20px;
        }
      }
    }
  }
`
