export const theme = {
  font: {
    primary: '"Roboto", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI"',
    secondary:
      '"Roboto", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI"',
  },
  textColor: {
    heading: "#1B1B1B",
    headingLight: "#FFFFFF",
    body: "#1B1B1B",
    bodyLight: "#FFFFFF",
    mono: "#1B1B1B",
    monoLight: "#FFFFFF",
    muted: "#4D4D4D",
    grey600: "#475467",
    grey800: "#1D2939",
    electricBlue: "#00A3E7",
  },
  colors: {
    primary: "#002DC2",
    white: "#FFF",
    offWhite: "#F0F0F0",
    offWhiteColor: "#E4E9F1",
  },
  spacing: {
    0: "0px",
    1: "2px",
    2: "4px",
    3: "8px",
    4: "16px",
    5: "24px",
    6: "32px",
    7: "40px",
    8: "48px",
    9: "64px",
    10: "96px",
  },
  typeScale: {
    1: "68px",
    2: "50px",
    3: "38px",
    4: "32px",
    5: "28px",
    6: "21px",
    7: "16px",
    8: "14px",
    9: "12px",
  },
  // Note that these correspond with the Typescale above^
  lineHeights: {
    1: "81.6px",
    2: "60px",
    3: "43.4px",
    4: "41.6px",
    5: "33.4px",
    6: "31.5px",
    7: "24px",
    8: "21px",
    9: "18px",
  },
  buttons: {
    primary: {
      backgroundColor: "#002DC2",
      textColor: "#FFFFFF",
      borderColor: "#002DC2",
      backgroundColorHover: "#5260FF",
      textColorHover: "#FFFFFF",
      borderColorHover: "#5260FF",
    },
    secondary: {
      backgroundColor: "#FFFFFF",
      textColor: "#002DC2",
      borderColor: "#002DC2",
      backgroundColorHover: "#5260FF",
      textColorHover: "#FFFFFF",
      borderColorHover: "#5260FF",
    },
    tertiary: {
      backgroundColor: "transparent",
      textColor: "#FFFFFF",
      borderColor: "#FFFFFF",
      backgroundColorHover: "#5260FF",
      textColorHover: "#FFFFFF",
      borderColorHover: "#5260FF",
    },
    // transparent bg button:
    quaternary: {
      backgroundColor: "transparent",
      textColor: "#FFFFFF",
      borderColor: "transparent",
      backgroundColorHover: "#5260FF",
      textColorHover: "#FFFFFF",
      borderColorHover: "#002DC2",
    },
  },
}
