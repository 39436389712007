import React from "react"
import { Link } from "gatsby"
// import shortid from 'shortid';
import { FooterWrapper } from "../styles/Footer.styled"
import WGLogoWhite from "@assets/icons/logo-white.svg"
import phoneWhite from "@assets/icons/phone-white.svg"
import emailWhite from "@assets/icons/email-white.svg"
import MktoForm from "../../Form/MktoForm"

import {
  SocialYouTube,
  SocialInstagram,
  SocialLinkedIn,
  SocialTwitter,
} from "../../../utils/IconsList"

const DesktopFooter = ({ footerData }) => {
  const formType = { type: "footer" }
  return (
    <FooterWrapper data-comp={DesktopFooter.displayName}>
      <div className="footer--top">
        <div className="footer--top-wrapper">
          <div className="footer--top-left-content">
            <div className="footer--logo-content">
              <Link to="/">
                <img
                  loading="lazy"
                  className="footer--logo-icon"
                  src={WGLogoWhite}
                  alt="Workgrid"
                />
              </Link>
            </div>
            <div className="footer--subscribe-content">
              <MktoForm formId={footerData.formId} component={formType} />
            </div>
            <div className="footer--contact-content">
              <p className="heading">
                {footerData.contactHeading ? footerData?.contactHeading : ""}
              </p>
              {footerData.contactSubheading ? (
                <p>{footerData?.contactSubheading}</p>
              ) : (
                <p></p>
              )}
              <a href={`tel:${footerData?.companyMeta?.phoneNumber}`}>
                <img loading="lazy" src={phoneWhite} alt="phone number" />
                {footerData?.companyMeta?.phoneNumber
                  ? footerData?.companyMeta?.phoneNumber
                  : ""}
              </a>
              <a href={`mailto:${footerData?.contactEmail}`}>
                <img loading="lazy" src={emailWhite} alt="email" />
                {footerData?.contactEmail ? footerData?.contactEmail : ""}
              </a>
            </div>
            <div className="footer--search-content">
              <div className="footer--review-content">
                <Link
                  target="_blank"
                  to="https://www.g2.com/products/workgrid/reviews"
                >
                  <img
                    loading="lazy"
                    src="/images/footer-review-us.png"
                    alt="Review us on G2"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="footer--top-right-content">
            <div className="footer--top-right-menu-content">
              {footerData?.navigationItems?.map(navItem => (
                <div className="footer--nav-menu" key={navItem?.id}>
                  <Link className="footer--nav-menu-title">
                    {navItem?.title}
                  </Link>
                  {(
                    navItem.menuCategories?.[0]?.menuItems || navItem?.menuItems
                  )?.map(menuItem => (
                    <Link
                      className="footer--nav-menu-item"
                      to={menuItem?.url}
                      key={menuItem.id}
                    >
                      {menuItem?.title}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
            <div className="footer--top-right-logo-content">
              {footerData?.logos.map(logo => {
                return (
                  <Link
                    className="footer--logo-icon"
                    key={logo?.primaryImage?.id}
                  >
                    <img
                      loading="lazy"
                      src={logo?.primaryImage?.file?.url}
                      alt={logo?.primaryImage?.description || "award logo"}
                    />
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="footer--top-mobile-content">
            <div className="footer--contact-content">
              <p className="heading">
                {footerData?.contactHeading ? footerData?.contactHeading : ""}
              </p>
              {footerData?.contactSubheading ? (
                <p>{footerData?.contactSubheading}</p>
              ) : (
                <p></p>
              )}
              <a href={`tel:${footerData?.companyMeta?.phoneNumber}`}>
                <img loading="lazy" src={phoneWhite} alt="phone number" />
                {footerData?.companyMeta?.phoneNumber
                  ? footerData?.companyMeta?.phoneNumber
                  : ""}
              </a>
              <a href={`mailto:${footerData?.contactEmail}`}>
                <img loading="lazy" src={emailWhite} alt="email" />
                {footerData?.contactEmail ? footerData?.contactEmail : ""}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer--bottom">
        <div className="footer--bottom-wrapper">
          <div className="footer--bottom-left-content">
            <span>{`© ${new Date().getFullYear()} Workgrid Software. All rights reserved.`}</span>
            <div className="footer--bottom-link-content">
              <Link className="footer--bottom-link" to="/privacy-policy">
                Privacy policy
              </Link>
              <Link className="footer--bottom-link" to="/evaluation-terms">
                Evaluation Terms
              </Link>
              <Link className="footer--bottom-link" to="/terms-and-conditions">
                Terms and conditions
              </Link>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                Cookie Settings
              </button>
            </div>
          </div>
          <div className="footer--bottom-right-content">
            {footerData?.companyMeta?.linkedInUrl && (
              <a
                className="footer--social-link"
                href={footerData?.companyMeta?.linkedInUrl}
                target="_blank"
                aria-label="Workgrid LinkedIn"
              >
                <SocialLinkedIn />
              </a>
            )}
            {footerData?.companyMeta?.twitterUrl && (
              <a
                className="footer--social-link"
                href={footerData?.companyMeta?.twitterUrl}
                target="_blank"
                aria-label="Workgrid Twitter"
              >
                <SocialTwitter />
              </a>
            )}
            {footerData?.companyMeta?.youtubeUrl && (
              <a
                className="footer--social-link"
                href={footerData?.companyMeta?.youtubeUrl}
                aria-label="Workgrid YouTube"
                target="_blank"
              >
                <SocialYouTube />
              </a>
            )}
            {footerData?.companyMeta?.instagramUrl && (
              <a
                className="footer--social-link"
                href={footerData?.companyMeta?.instagramUrl}
                target="_blank"
                aria-label="Workgrid Instagram"
              >
                <SocialInstagram />
              </a>
            )}
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default DesktopFooter
DesktopFooter.displayName = "DesktopFooter"
