import React from 'react'
import { Button } from "@atoms"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const defaultResolveRichText = ( contentObject ) => {
  
  const content = JSON.parse(contentObject?.raw)
  const links = contentObject?.references

  const renderOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
      [MARKS.UNDERLINE]: text => <span className="text-underline">{text}</span>,
      [MARKS.CODE]: (text, story) => <SyntaxHighlighter style={materialDark} language={story} wrapLines wrapLongLines>{text?.trim() || ""}</SyntaxHighlighter>
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.QUOTE]: (node, children) => <div className="quote">{children}</div>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          node.content.length === 1 &&
          node.content[0].marks.find((x) => x.type === "code")
        ) {
          return <div>{children}</div>
        }
        return <p>{children}</p>
      },
      [BLOCKS.UL_LIST]: (node, children) => <ul className="ul-list">{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol className="ol-list">{children}</ol>,
      [BLOCKS.HR]: (node, children) => <div className="hr">{children}</div>,
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = node.data.target.sys.id
        const asset = links?.filter(subComp => subComp?.contentful_id === id)
        if (asset){
          switch (asset[0]?.sys?.contentType?.sys?.id) {
            case "componentCta":
              return (
                <Button
                  ctaVariant='primary'
                  ctaLink={asset[0]?.link}
                >
                  {asset[0]?.label}
                </Button>
              );
            default:
              break;
          }
        }
        return null;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={node?.data?.uri} target="_blank" rel="noopener noreferrer">{children}</a>
      }
    }
  }
  // @ts-ignore
  return documentToReactComponents(content, renderOptions)
};
