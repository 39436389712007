import React, { useState, useEffect } from 'react';
import { UIProvider } from '@contexts/UIContext';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import SimpleHeader from '../Header/SimpleHeader';
import SimpleFooter from '../Footer/SimpleFooter';

// NOTE: Commenting out the useActiveElement function and related useEffect for now. 
// Keeping it in as opposed to deleting for use in future accessibility investigations 

// const useActiveElement = () => {
//   const [active, setActive] = useState(document.activeElement);

//   const handleFocusIn = (e) => {
//     setActive(document.activeElement);
//   }

//   useEffect(() => {
//     document.addEventListener('focusin', handleFocusIn)
//     return () => {
//       document.removeEventListener('focusin', handleFocusIn)
//   };
//   }, [])

//   return active;
// }

export const Layout = ({ children, headerTheme, simpleHeader, simpleHeaderCta, simpleFooter }) => {

  // const focusedElement = useActiveElement();

  // useEffect(() => {
  //    if (focusedElement) {
  //      focusedElement.value && console.log(focusedElement.value);
  //    }
  //   console.log(focusedElement);
  // }, [focusedElement])

  return (
    <UIProvider>
      <main>
        <div
          style={{ minHeight: '100vh' }}
        >
          {simpleHeader ? <SimpleHeader simpleHeaderCta={simpleHeaderCta} /> : <Header theme={headerTheme} />
          }
          {children}
        </div>
        {simpleFooter ? <SimpleFooter /> : <Footer />}
      </main>
    </UIProvider>
  );
}