import React, { useReducer, useContext, createContext } from 'react';
const UIContext = createContext(null);


const initialUiState = {
  mobileMenuVisible: false,
  overlayVisible: false,
}

const uiReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MOBILE_MENU':
      return {
        ...state,
        overlayVisible: true,
        mobileMenuVisible: true
      };
      case 'CLOSE_ALL':
        return {
          ...state,
          overlayVisible: false,
          mobileMenuVisible: false
        };
        default: {
          throw new Error(`Unhandled action type passed to UIContext's Reducer: ${action.type}`)
        }
      }
    };
    
const UIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initialUiState);
  const value = {state, dispatch};
  return<UIContext.Provider value={value}>{children}</UIContext.Provider>
}

function useUI() {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a CountProvider')
  }
  return context
}

export {UIProvider, useUI}