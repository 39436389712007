import React from "react"
import LoadingLogo from "./LoadingLogo";
import { LoadingWrapper, LoadingDescription } from "./styles/MtkoForm.styled";

const LoadingAnimation = ({ background }) => {
  let fontColor
  switch (background) {
    case "white":
      fontColor = "blue";
      break
    case "blue":
      fontColor = "white";
      break
    default:
      fontColor = "blue";
  }

  return (
    <LoadingWrapper>
      <LoadingLogo color={fontColor} />
      <LoadingDescription fontColor={fontColor}>
        Loading... please wait
      </LoadingDescription>
    </LoadingWrapper>
  )
}

export default LoadingAnimation
