import { css } from "styled-components"

export const fontSize = {
  text: {
    xs: { fontSize: "12", lineHeight: "16", letterSpacing: "0" },
    sm: { fontSize: "14", lineHeight: "20", letterSpacing: "0" },
    reg: { fontSize: "16", lineHeight: "24", letterSpacing: "0" },
    md: { fontSize: "20", lineHeight: "26", letterSpacing: "0"},
    lg: { fontSize: "22", lineHeight: "30", letterSpacing: "0" },
    xl: { fontSize: "28", lineHeight: "34", letterSpacing: "0.1" },
  },
  display: {
    xxs: { fontSize: "22", lineHeight: "30", letterSpacing: "0" },
    xs: { fontSize: "28", lineHeight: "34", letterSpacing: "0" },
    sm: { fontSize: "32", lineHeight: "42", letterSpacing: "0" },
    md: { fontSize: "38", lineHeight: "48", letterSpacing: "0" },
    lg: { fontSize: "50", lineHeight: "60", letterSpacing: "0" },
    xl: { fontSize: "68", lineHeight: "72", letterSpacing: "0" },
  },
  kicker: {
    sm: { fontSize: "12", lineHeight: "16", letterSpacing: "2"},
    reg: { fontSize: "14", lineHeight: "14", letterSpacing: "2" },
  },
}

/*
 *   ${font('display', 'xl', '700')}
 */
export const font = (style, size, weight) => {
  return css`
    font-size: ${fontSize[style][size].fontSize ||
    fontSize.text.reg.fontSize}px;
    font-weight: ${weight || 400};
    font-family: "Roboto", "sans-serif";
    line-height: ${fontSize[style][size].lineHeight ||
    fontSize.text.reg.lineHeight}px;
    letter-spacing: ${fontSize[style][size].letterSpacing ||
    fontSize.text.reg.letterSpacing}px;
    margin-bottom: 0;
    ${style === "kicker" &&
    css`
      text-transform: uppercase;
    `}
  `
}
